<template lang="pug">
.savedCard
	p.savedCard__name {{savedCreditCard.cardholder_name}}
	p.savedCard__number **** - **** - **** - {{savedCreditCard.last_4}}
	div
		icon.savedCard__icon(name='american', :original="true", v-if="savedCreditCard.card_type == 'American Express'")
		icon.savedCard__icon(name='visa', :original="true", v-if="savedCreditCard.card_type == 'Visa'")
		icon.savedCard__icon(name='master', :original="true", v-if="savedCreditCard.card_type == 'MasterCard'")
		icon.savedCard__icon(name='diners', :original="true", v-if="savedCreditCard.card_type == 'Diners Club'")
		icon.savedCard__icon(name='jcb', :original="true", v-if="savedCreditCard.card_type == 'JCB'")
		icon.savedCard__icon(name='discover', :original="true", v-if="savedCreditCard.card_type == 'Discover'")
	a.btn.btn--white(@click="SET_SAVED_CREDIT_CARD") Use different card
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('auth', ['isAuthenticated']),
        ...mapState('cart', ['savedCreditCard'])
    },
    methods: {
        SET_SAVED_CREDIT_CARD() {
            this.$store.commit('cart/SET_SAVED_CREDIT_CARD', null)
        }
    }
}
</script>

<style lang="sass" scoped>
@import ~@/sass/importer

@import ~@/sass/importer


.savedCard
	font-size: 1.8rem
	color: $white
	margin: 60px 0
	text-align: center

	&__name, &__number
		font-size: 1.6rem

	&__number
		margin-top: 8px

	&__link
		display: inline-block
		color: $color-primary
		margin-top: 20px

	&__icon
		+size(40px, 24px)
		margin: 10px auto 30px
</style>
