var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
      },
      [_c("VLoadSpinner", { attrs: { "on-page": "cart-payment" } })],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading",
          },
        ],
      },
      [
        _c("cart-heading", {
          attrs: {
            title: "Your order total is $" + _vm.cartTotalAmount,
            subtitle:
              "After successful payment you will be redirected to the download page.",
          },
        }),
        _c(
          "form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isCardPaymentType && !_vm.savedCreditCard,
                expression: "isCardPaymentType && !savedCreditCard",
              },
            ],
            staticClass: "form formLayout",
            attrs: { name: "payment" },
          },
          [
            _c("div", { staticClass: "formGroup" }, [
              !_vm.isAuthenticated
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.customer.email,
                        expression: "customer.email",
                      },
                    ],
                    staticClass: "formInput formInput--fullWidth",
                    attrs: { placeholder: "Delivery Email" },
                    domProps: { value: _vm.customer.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.customer, "email", $event.target.value)
                      },
                    },
                  })
                : _vm._e(),
            ]),
            _c("div", { staticClass: "formGroup" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.customer.name,
                    expression: "customer.name",
                  },
                ],
                staticClass: "formInput formInput--fullWidth",
                attrs: { placeholder: "Card holder's name" },
                domProps: { value: _vm.customer.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.customer, "name", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._m(0),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isAuthenticated,
                    expression: "isAuthenticated",
                  },
                ],
                staticClass: "formGroup h-textLeft",
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.savePaymentInfo,
                      expression: "savePaymentInfo",
                    },
                  ],
                  staticClass: "formCheckbox",
                  attrs: {
                    id: "save-purchase",
                    type: "checkbox",
                    name: "save-purchase",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.savePaymentInfo)
                      ? _vm._i(_vm.savePaymentInfo, null) > -1
                      : _vm.savePaymentInfo,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.savePaymentInfo,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.savePaymentInfo = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.savePaymentInfo = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.savePaymentInfo = $$c
                      }
                    },
                  },
                }),
                _c("label", { attrs: { for: "save-purchase" } }, [
                  _vm._v("Save for future purchase"),
                ]),
              ]
            ),
            _c("input", {
              staticStyle: {
                position: "absolute",
                left: "-9999px",
                width: "1px",
                height: "1px",
              },
              attrs: { type: "submit", value: "submit" },
            }),
          ]
        ),
        _vm.isCardPaymentType && _vm.savedCreditCard
          ? _c("saved-credit-card")
          : _vm._e(),
        _c(
          "form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isPaypalPaymentType,
                expression: "isPaypalPaymentType",
              },
            ],
            staticStyle: { "max-width": "300px", margin: "0 auto" },
          },
          [
            _c("div", { staticClass: "formGroup" }, [
              !_vm.isAuthenticated
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.customer.email,
                        expression: "customer.email",
                      },
                    ],
                    staticClass: "formInput formInput--fullWidth",
                    attrs: { placeholder: "Delivery Email" },
                    domProps: { value: _vm.customer.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.customer, "email", $event.target.value)
                      },
                    },
                  })
                : _vm._e(),
            ]),
            _c("div", { ref: "paypalButton", staticClass: "h-mt-20" }),
          ]
        ),
        _vm.paymentErrorShort
          ? _c("div", { staticClass: "form__error" }, [
              _vm._v(_vm._s(_vm.paymentErrorShort)),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "cartNavigation",
            class: {
              "cartNavigation--btnFullWidthWrapper": _vm.isCardPaymentType,
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "btn btn--lg btn--darkGrey btn--border",
                on: {
                  click: function ($event) {
                    return _vm.SET_CART_STEP(_vm.CartStep.Review)
                  },
                },
              },
              [_vm._v("PREVIOUS")]
            ),
            _vm.isCardPaymentType
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn--lg btn--secondary",
                    on: { click: _vm.payWithCreditCard },
                  },
                  [_vm._v("PAY $" + _vm._s(_vm.cartTotalAmount))]
                )
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "formGroup formGroup--col2" }, [
      _c("div", { staticClass: "hosted-field", attrs: { id: "card-number" } }),
      _c("div", { staticClass: "formGroup--col2" }, [
        _c("div", {
          staticClass: "hosted-field",
          attrs: { id: "expiration-date", "cc-exp": "" },
        }),
        _c("div", { staticClass: "hosted-field", attrs: { id: "cvv" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }