// tslint:disable-block

export const HOSTED_FIELDS_PLACEHOLDERS = {
    number: {
        selector: '#card-number',
        placeholder: 'CARD NUMBER'
    },
    cvv: {
        selector: '#cvv',
        placeholder: 'CVV'
    },
    expirationDate: {
        selector: '#expiration-date',
        placeholder: 'MM / YY'
    }
}

export const HOSTED_FIELDS_STYLE = {
    "input": {
        'font-size': '13px',
        "color": '#fff'
    },
    '.valid': {
        color: '#fff'
    },
    // placeholder styles need to be individually adjusted
    '::-webkit-input-placeholder': {
        "color": '#909090',
        'font-size': '10px',
        'text-transform': 'uppercase'
    },
    ':-moz-placeholder': {
        "color": '#909090',
        'font-size': '10px',
        'text-transform': 'uppercase'
    },
    '::-moz-placeholder': {
        "color": '#909090',
        'font-size': '10px',
        'text-transform': 'uppercase'
    },
    ':-ms-input-placeholder': {
        "color": '#909090',
        'font-size': '10px',
        'text-transform': 'uppercase'
    },
    '::placeholder': {
        'text-transform': 'uppercase'
    }
}

// payments styles
const PAYPAL_BUTTON_STYLE = {
    size: 'responsive',
    color: 'silver',
    shape: 'rect',
    label: 'paypal',
    tagline: false
}
const STRIPE_STYLE = {
    base: {
        "color": '#fff',
        "fontSize": '13px',
        "fontWeight": '700',
        '::placeholder': {
            color: '#585858',
            fontSize: '11px',
            textTransform: 'uppercase',
            fontWeight: '400'
        }
    }
}

export { STRIPE_STYLE, PAYPAL_BUTTON_STYLE }
