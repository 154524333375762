var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "savedCard" }, [
    _c("p", { staticClass: "savedCard__name" }, [
      _vm._v(_vm._s(_vm.savedCreditCard.cardholder_name)),
    ]),
    _c("p", { staticClass: "savedCard__number" }, [
      _vm._v("**** - **** - **** - " + _vm._s(_vm.savedCreditCard.last_4)),
    ]),
    _c(
      "div",
      [
        _vm.savedCreditCard.card_type == "American Express"
          ? _c("icon", {
              staticClass: "savedCard__icon",
              attrs: { name: "american", original: true },
            })
          : _vm._e(),
        _vm.savedCreditCard.card_type == "Visa"
          ? _c("icon", {
              staticClass: "savedCard__icon",
              attrs: { name: "visa", original: true },
            })
          : _vm._e(),
        _vm.savedCreditCard.card_type == "MasterCard"
          ? _c("icon", {
              staticClass: "savedCard__icon",
              attrs: { name: "master", original: true },
            })
          : _vm._e(),
        _vm.savedCreditCard.card_type == "Diners Club"
          ? _c("icon", {
              staticClass: "savedCard__icon",
              attrs: { name: "diners", original: true },
            })
          : _vm._e(),
        _vm.savedCreditCard.card_type == "JCB"
          ? _c("icon", {
              staticClass: "savedCard__icon",
              attrs: { name: "jcb", original: true },
            })
          : _vm._e(),
        _vm.savedCreditCard.card_type == "Discover"
          ? _c("icon", {
              staticClass: "savedCard__icon",
              attrs: { name: "discover", original: true },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "a",
      {
        staticClass: "btn btn--white",
        on: { click: _vm.SET_SAVED_CREDIT_CARD },
      },
      [_vm._v("Use different card")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }