







































import { Component, Mixins } from 'vue-property-decorator'
import { HOSTED_FIELDS_STYLE, HOSTED_FIELDS_PLACEHOLDERS, PAYPAL_BUTTON_STYLE } from '~/const/payment-styles'
// functions and mixins
import { analyticsPageView, analyticsCheckoutAddPaymentInfo } from '~/services/analytics'
// components
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import CartHeading from './CartHeading.vue'
import SavedCreditCard from './SavedCreditCard.vue'
import { CartStore, CartStep, PaymentType } from '~/store/cart'
import { initBraintree, initSession, fetchSavedPaymentInfo } from '@/api/payment'
import { client, hostedFields, dataCollector, ThreeDSecureVerifyPayload, threeDSecure, CreditCardInfo } from 'braintree-web'
import braintree from 'braintree-web'
import { fetchClientToken } from '~/api/payment'
import { AbstractCartPaymentForm } from '@/mixins/abstract-cart-payment-form'
import { ENV_IS_PRODUCTION } from '~/const/environment'

@Component({ components: { VLoadSpinner, CartHeading, SavedCreditCard } })
export default class CartPaymentForm extends Mixins(AbstractCartPaymentForm) {
    CartStep = CartStep

    paymentPlatform = 'Braintree'
    // BRAINTREE
    hostedFieldsInstance: any = null // dont know why this was needed
    deviceData: any = null // this is the data used to prevent frauds
    threeDSecure: any = null
    // whether to save or not new payment info
    savePaymentInfo = false

    @CartStore.State('selectedPaymentType') selectedPaymentType: PaymentType
    @CartStore.State('savedCreditCard') savedCreditCard: CreditCardInfo

    get isPaypalPaymentType(): boolean {
        return this.selectedPaymentType === PaymentType.PayPal
    }

    get isCardPaymentType(): boolean {
        return this.selectedPaymentType === PaymentType.CreditCard
    }

    get paypalEnv(): string {
        return ENV_IS_PRODUCTION ? 'production' : 'sandbox'
    }

    get defaultPaymentParams(): IPaymentParams {
        return {
            customer_name: this.customer.name,
            save_payment_info: this.savePaymentInfo,
            // braintree
            use_paypal: false,
            device_data: this.deviceData
        }
    }

    @CartStore.Mutation('RESET_CART_STEP') RESET_CART_STEP: () => void
    @CartStore.Mutation('SET_SAVED_CREDIT_CARD') SET_SAVED_CREDIT_CARD: (payload: CreditCardInfo) => void

    async initBraintree() {
        const clientToken = await fetchClientToken()
        client.create({ authorization: clientToken }, (clientErr, clientInstance) => {
            // an error occured
            if (clientErr) {
                console.error(clientErr)
                this.paymentError = clientErr
                return
            }
            // collect device data
            dataCollector.create(
                {
                    client: clientInstance,
                    kount: true,
                    paypal: this.isPaypalPaymentType
                },
                (err, dataCollectorInstance) => {
                    // At this point, you should access the dataCollectorInstance.deviceData value and provide it
                    // to your server, e.g. by injecting it into your form as a hidden input.
                    if (err) {
                        console.error('Error during Collection of Device Data:', err)
                    } else {
                        this.deviceData = dataCollectorInstance.deviceData
                    }
                }
            )

            threeDSecure.create(
                {
                    version: 2,
                    client: clientInstance
                },
                (threeDSecureErr, threeDSecureInstance) => {
                    if (threeDSecureErr) {
                        // Handle error in 3D Secure component creation
                        console.error(threeDSecureErr)
                        this.paymentError = threeDSecureErr
                        return
                    }

                    this.threeDSecure = threeDSecureInstance
                }
            )

            // if using paypal
            if (this.isPaypalPaymentType) {
                const self = this
                // Create a PayPal Checkout component.
                // @ts-ignore
                braintree.paypalCheckout.create({ client: clientInstance }, (paypalCheckoutErr, paypalCheckoutInstance) => {
                    // Stop if there was a problem creating PayPal Checkout.
                    // This could happen if there was a network error or if it's configured incorrectly.
                    if (paypalCheckoutErr) {
                        this.paymentError = paypalCheckoutErr
                        console.error('Error creating PayPal Checkout:', paypalCheckoutErr)
                        return
                    }

                    // Set up PayPal with the checkout.js library
                    // @ts-ignore
                    paypal.Button.render(
                        {
                            // @ts-ignore
                            env: this.paypalEnv,
                            // @ts-ignore
                            style: PAYPAL_BUTTON_STYLE,
                            commit: true, // This will add the transaction amount to the PayPal button
                            payment() {
                                console.log(paypalCheckoutInstance)
                                return paypalCheckoutInstance.createPayment({
                                    flow: 'checkout',
                                    amount: self.cartTotalAmount,
                                    currency: 'USD'
                                })
                            },
                            onAuthorize(data: any, actions: any) {
                                return paypalCheckoutInstance.tokenizePayment(data, (err: any, payload: any) => {
                                    // pay the selected beats and license and use paypal
                                    const dispatchParams = {
                                        ...self.defaultPaymentParams,
                                        use_paypal: true,
                                        nonce_from_the_client: payload.nonce,
                                        customer_name: self.customer.name ? self.customer.name : `${payload.details.firstName} ${payload.details.lastName}`,
                                        customer_email: self.customer.email ? self.customer.email : payload.details.email
                                        // country: payload.details.countryCode
                                    }
                                    self.requestPayment(dispatchParams)
                                })
                            },
                            onCancel(data: any) {
                                self.RESET_CART_STEP() // go back to first cart page
                            },
                            onError(err: any) {
                                self.paymentError = err
                            }
                        },
                        this.$refs.paypalButton
                    )
                })
            } else {
                // otherwise init custom layout => credit card form
                hostedFields.create(
                    { client: clientInstance, styles: HOSTED_FIELDS_STYLE, fields: HOSTED_FIELDS_PLACEHOLDERS },
                    (hostedFieldsErr, hostedFieldsInstance) => {
                        if (hostedFieldsErr) {
                            this.paymentError = hostedFieldsErr
                        } else {
                            this.hostedFieldsInstance = hostedFieldsInstance
                        }
                    }
                )
            }
        })
    }

    payWithCreditCard() {
        // pay the selected beats and license and use old credit card info
        if (this.savedCreditCard) this.requestPayment(this.defaultPaymentParams)
        else {
            this.SET_LOADING_STATE(true)
            this.hostedFieldsInstance.tokenize((tokenizeErr: any, payload: any) => {
                if (tokenizeErr) {
                    this.paymentError = tokenizeErr.message
                    this.SET_LOADING_STATE(false)
                } else {
                    this.threeDSecure.verifyCard(
                        {
                            amount: this.cartTotalAmount,
                            nonce: payload.nonce,
                            bin: payload.bin,
                            email: this.customer.email,
                            onLookupComplete: (data: any, next: any) => {
                                // use `data` here, then call `next()`
                                next()
                            }
                        },
                        (err: any, response: ThreeDSecureVerifyPayload) => {
                            if (err) {
                                this.paymentError = err.message
                                this.SET_LOADING_STATE(false)
                            } else {
                                // pay the selected beats and license and use new credit card info
                                this.requestPayment({ ...this.defaultPaymentParams, nonce_from_the_client: response.nonce })
                            }
                        }
                    )
                }
            })
        }
    }

    async fetchSavedPaymentInfo() {
        const data = await fetchSavedPaymentInfo()
        this.SET_SAVED_CREDIT_CARD(data)
    }

    async requestPayment(paymentParams: IPaymentParams) {
        analyticsCheckoutAddPaymentInfo()
        // sets loader
        this.SET_LOADING_STATE(true)
        // send the request to pay
        try {
            const orderId = await initSession(this.initSessionParams)
            const orderData = await initBraintree(orderId, paymentParams)
            this.onCompletedPayment(orderData)
        } catch (err: any) {
            this.$store.dispatch('modal/showErrorUnexpected')
            if (err.response) this.paymentError = err.response.data
            console.error(err)
        }
        // resets loader
        this.SET_LOADING_STATE(false)
    }

    mounted() {
        // check if we have saved payment method from previous purchases
        if (this.isAuthenticated && this.isCardPaymentType) this.fetchSavedPaymentInfo()
        this.initBraintree()
        // send page view event to GA for payment form
        analyticsPageView({ page: `cart/payment-${this.selectedPaymentType}`, title: 'Cart Payment Form' })
    }
}
